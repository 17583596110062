<template>
    <div style="padding: 40px;">
        <div v-show="pageCurrent == 0 && !isDetails">
            <div style="margin-bottom:30px">
                <span style="color: red;font-size: 15px;">*</span>
                <span style="color: #000;font-size: 15px;font-weight: 500;"> 商品类型 </span>
                <a-radio-group v-model="form.type" name="radioGroup" @change="handleSelectChange"
                    :disabled="isDetails || $route.query.id">
                    <a-radio :value="1">
                        单品
                    </a-radio>
                    <a-radio :value="2">
                        组合品
                    </a-radio>
                </a-radio-group>
                <p style="margin-top: 25px;" v-if="form.type == 2">
                    <a-button type="primary" @click="next">下 一 步</a-button>
                </p>
            </div>
            <div v-if="form.type == 1">
                <p>
                    <span style="color: red;font-size: 15px;">*</span>
                    <span style="color: #000;font-size: 15px;font-weight: 500;"> 商品品类 </span>
                    <span style="color: red;"> (分类资质为审核通过时则可以进行选择。若需要修改或增加，请前往【资质管理】-【分类资质】进行修改。) </span>
                </p>
                <a-select :default-value="isDetails || $route.query.id ? 1 : []"
                    style="width: 220px;margin-right: 10px;" @change="handleCategory" placeholder="一级类目">
                    <a-select-option :value="1">商城</a-select-option>
                </a-select>
                <a-select v-model="twoId" style="width: 220px;margin-right: 10px" @change="handleCategory2"
                    placeholder="二级类目">
                    <a-select-option :value="item.classifyId" v-for="item in classifyList2" :key="item.classifyId">{{
            item.classifyName }}</a-select-option>
                </a-select>
                <a-select v-model="threeId" style="width: 220px;margin-right: 10px" @change="handleCategory3"
                    placeholder="三级类目">
                    <a-select-option :value="item.classifyId" v-for="item in classifyList3" :key="item.classifyId">{{
            item.classifyName }}</a-select-option>
                </a-select>
                <a-select v-model="fourId" style="width: 220px" placeholder="四级类目" @change="handleCategory4">
                    <a-select-option :value="item.classifyId" v-for="item in classifyList4" :key="item.classifyId">{{
            item.classifyName }}</a-select-option>
                </a-select>
                <p style="margin-top: 15px;">
                    <a-button type="primary" @click="next" :disabled="!nextShow">下 一 步</a-button>
                </p>
            </div>
        </div>
        <div v-show="pageCurrent == 1">
            <p style="color:#333;font-weight:500;font-size:14px" v-if="form.type == 1">
                您当前选择的是： <span v-if="!isDetails && twoName">商城>{{ twoName }}>{{ threeName }}>{{ fourName }}</span>
                <!-- <span v-if="isDetails ">{{ classifyDesc }}</span> -->
                <span v-else>{{ classifyDesc }}</span>
                <a-button v-if="!isDetails" @click="pageCurrent = 0" type="primary"
                    style="margin-left:10px;">重选品类</a-button>
            </p>
            <span style="color: #000;font-size: 15px;font-weight: 500;">
                基本信息
                <a-button v-if="!isDetails && form.type == 2 && !$route.query.id" @click="pageCurrent = 0"
                    type="primary" style="margin-left:10px;">重选商品类型</a-button>
            </span>

            <div class="box">
                <span class="box-tit">基本信息</span>
                <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
                    <a-row>
                        <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" :md="{ span: 8 }" :lg="{ span: 8 }">
                            <a-form-model-item label="商品名称" required>
                                <div style="display: flex;align-items: center;">
                                    <a-input v-model="form.name" :max-length="80" placeholder="请输入商品名称"
                                        :disabled="isDetails || ($route.query.id && $route.query.detailStatus != 0)" />
                                    <div style="width: 70px;text-align: right;">
                                        <a-tooltip>
                                            <template slot="title">
                                                填写spu名称【品牌】【品名】【商品描述（选填）】【产地（选填）】【包装】中间用空格分隔。例如：三都港 冻虾仁 特级南美白对虾 3包装
                                            </template>
                                            <span style="font-size: 13px;">
                                                <a-icon type="question-circle" />
                                                <span style="color: #e6b915;">规范</span>
                                            </span>

                                        </a-tooltip>
                                    </div>
                                </div>
                            </a-form-model-item>
                        </a-col>

                        <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" :md="{ span: 8 }" :lg="{ span: 8 }">
                            <a-form-model-item label="供应商自查spu编码" :label-col="{ span: 8 }" >
                                <div style="display: flex;align-items: center;">
                                    <a-input v-model="form.spuCode" placeholder="请输入供应商自查spu编码" :disabled="isDetails" />
                                    <div style="width: 70px;text-align: right;">
                                        <a-tooltip>
                                            <template slot="title">
                                                商品本身在公司的唯一编码，非规格编码，比如玩具，单个颜色有唯一的规格编码，但是这个玩具本身也有一个唯一编码，即供应商自查spu编码。
                                            </template>
                                            <span style="font-size: 13px;">
                                                <a-icon type="question-circle" />
                                                <span style="color: #e6b915;">说明</span>
                                            </span>
                                        </a-tooltip>
                                    </div>
                                </div>
                            </a-form-model-item>
                        </a-col>
                        <a-col v-if="form.type == 2" :xs="{ span: 24 }" :sm="{ span: 8 }" :md="{ span: 8 }"
                            :lg="{ span: 8 }">
                            <a-form-model-item label="库存" :label-col="{ span: 8 }" required>
                                <div style="display: flex;align-items: center;">
                                    <a-input v-model="form.stock" placeholder="请输入库存" :disabled="isDetails" />
                                </div>
                            </a-form-model-item>
                        </a-col>
                        <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" :md="{ span: 8 }" :lg="{ span: 8 }">
                            <a-form-model-item label="配送规则" :label-col="{ span: 8 }">
                                <div style="display: flex;align-items: center;">
                                    <a-select allowClear placeholder="请选择配送规则" v-model="form.deliveryScopeId" :disabled="isDetails"
                                        option-label-prop="label">
                                        <a-select-option  v-for="item in deliveryScopeIdList"  :key="item.id" :value="item.id" :label="item.name">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                 
                    <a-row style="width: 1100px;">
                        <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :lg="{ span: 16 }">
                            <a-form-model-item label="是否支持7天无理由" required>
                                <a-radio-group v-model="form.returnable" @change="onChange"
                                    :disabled="isDetails || ($route.query.id && $route.query.detailStatus != 0)">
                                    <a-radio :value="1">是</a-radio>
                                    <a-radio :value="2">否</a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                        </a-col>
                    </a-row>

                    <!-- <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                    <a-button type="primary" @click="onSubmit">
                        Create
                    </a-button>
                    <a-button style="margin-left: 10px;" @click="resetForm">
                        Reset
                    </a-button>
                </a-form-model-item> -->

                </a-form-model>
            </div>

            <div class="box">
                <!-- 单品 -->
                <div v-if="form.type == 1">
                    <div>
                        <span class="box-tit">规格信息 </span>
                        <a-tooltip>
                            <template slot="title">

                                <span v-if="form.skuType == 1">商品的规格是固定 唯一的。例如：商品三都港 冻虾仁
                                    每一包克数含量都为100g/包。则这种就为单规格商品</span>
                                <span
                                    v-if="form.skuType == 2">商品有规格、口味、颜色等多种规格，例如：颜色：红色、黄色这类商品点击添加规格选择多规格后，点击生成规格产品则会自动生成2种规格商品</span>
                            </template>
                            <span style="font-size: 13px;">
                                <a-icon type="question-circle" />
                                <span style="color: #e6b915;"> 说明</span>
                            </span>
                        </a-tooltip>
                    </div>
                    <div style="margin-bottom: 10px;" v-if="!isDetails">
                        <a-radio-group v-model="form.skuType" @change="skuTypeChange" style="margin-top: 20px;"
                            :disabled="$route.query.id && $route.query.detailStatus != 0">
                            <a-radio :value="1">单规格</a-radio>
                            <a-radio :value="2">多规格</a-radio>
                        </a-radio-group>
                    </div>

                    <div v-if="form.skuType == 2 && !isDetails" style="margin-top:20px;">
                        <div v-if="($route.query.id && $route.query.detailStatus == 0) || !$route.query.id">
                            <a-button style="margin: 0 0 20px 0;" @click="addSkuWrap" v-if="skuWrapList.length < 3"
                                type="primary">增加规格</a-button>
                            <ul class="sku-ul">
                                <li><span style="color:red;">*</span>规格名</li>
                                <li><span style="color:red;">*</span>规格值</li>
                                <li><span style="color:red;">*</span>操作</li>
                            </ul>
                            <a-divider />
                            <ul style="display:flex;overflow-x: auto" v-for="(item, index) in skuWrapList" :key="index">
                                <li
                                    style="display:flex;align-items:center;margin-right:10px;width:520px;position:relative">

                                    <a-input placeholder="规格名" style="width:160px;margin-right:10px;"
                                        v-model="item.name"></a-input>
                                    <span v-if="item.children"
                                        style="margin-right:10px;display:flex;align-items:center">
                                        <span v-for="(it, idx) in item.children" :key="idx"
                                            style="margin-right:10px;display:flex;align-items:center">
                                            <a-input style="width:120px;margin-right:5px;" placeholder="规格值"
                                                v-model="it.name"></a-input>
                                            <a-icon @click="delSkuName(index, idx)" type="delete" />
                                        </span>
                                    </span>
                                    <a-button type="link" v-if="skuWrapList[index].children.length < 10"
                                        @click="addSkuName(index)">添加规格值</a-button>

                                    <a-button type="link" @click="delSkuRow(index)" style="color:red;"
                                        v-if="index > 0">删除</a-button>
                                </li>
                            </ul>
                            <a-divider />
                            <a-button type="primary" @click="saveSkuGG">生成规格产品</a-button>
                        </div>

                    </div>

                </div>
                <!-- 组合 -->
                <div v-if="form.type == 2">
                    <a-button @click="addGoods" v-if="!isDetails && $route.query.detailStatus == 0"
                        type="primary">增加组合商品</a-button>
                    <a-button @click="addGoods" v-if="!isDetails && !$route.query.id" type="primary">增加组合商品</a-button>
                    <div style="display:flex;padding-top:30px;font-size:14px">
                        <p style="margin-right:10px">
                            组合合计价格：
                        </p>
                        <p style="margin-right:20px">
                            市场建议零售价:
                            <span style="font-weight:500;color:red">¥{{ getSkuListPirce(0) }}</span>
                        </p>
                        <p>
                            含税运代发成本:
                            <span style="font-weight:500;color:red">¥{{ getSkuListPirce(1) }}</span>
                        </p>
                    </div>
                </div>
                <div class="table-scroll">
                    <div class="table-box">
                        <ul class="table-tit">
                            <li style="display:flex;justify-content: center;">
                                <span style="color:red;">*</span>
                                规格图片
                                <div style="width: 42px;text-align: right;">
                                    <a-tooltip>
                                        <template slot="title">
                                            <span>商品每个规格的图片，例如：苹果11 紫色 64g的商品图片</span>
                                        </template>
                                        <span style="font-size: 13px;">
                                            <a-icon type="question-circle" />
                                            <span style="color: #e6b915;">说明</span>
                                        </span>
                                    </a-tooltip>
                                </div>
                            </li>
                            <!-- 商品每个规格的图片，例如：苹果11 紫色 64g的商品图片 -->
                            <li>
                                <span style="color:red;">*</span>
                                规格
                            </li>
                            <li>
                                <!-- 组合必填条形码 -->
                                <span style="color:red;" v-if="form.type == 1">*</span>
                                条形码
                            </li>
                            <li>
                                <!-- <span style="color:red;">*</span> -->
                                供应商自查sku编码
                            </li>
                            <li>
                                <span style="color:red;">*</span>
                                市场建议零售价
                            </li>
                            <li>
                                <span style="color:red;">*</span>
                                含税运代发成本
                            </li>
                            <li style="display:flex">
                                <!-- <span style="color:red;">*</span> -->
                                集采数量
                                <div style="width: 42px;text-align: right;">
                                    <a-tooltip>
                                        <template slot="title">
                                            <span>集采数量与含税运集采成本相互关联。例如：集采数量“100件”，给到的含税运集采成本为 “50元”</span>
                                        </template>
                                        <span style="font-size: 13px;">
                                            <a-icon type="question-circle" />
                                            <span style="color: #e6b915;">说明</span>
                                        </span>
                                    </a-tooltip>
                                </div>
                            </li>
                            <li>
                                <!-- <span style="color:red;">*</span> -->
                                含税运集采成本
                            </li>
                            <!-- <li>
                                <span style="color:red;">*</span>
                                库存
                            </li> -->
                            <li style="display:flex" v-if="form.type == 2">
                                <span style="color:red;">*</span>
                                数量
                                <div style="width: 42px;text-align: right;">
                                    <a-tooltip>
                                        <template slot="title">
                                            <span>数量为该条sku商品的数量。例如：A商品售价50元，发货时会发2个A商品，则数量就填写为2。</span>
                                        </template>
                                        <span style="font-size: 13px;">
                                            <a-icon type="question-circle" />
                                            <span style="color: #e6b915;">说明</span>
                                        </span>
                                    </a-tooltip>
                                </div>

                            </li>
                            <li v-if="form.type == 1">
                                <span style="color:red;">*</span>
                                所在仓库
                            </li>
                            <li v-if="form.type == 1">
                                <span style="color:red;">*</span>
                                参考售价
                            </li>
                            <li v-if="form.type == 1">
                                <span style="color:red;">*</span>
                                供货信息
                            </li>
                            <li v-if="form.skuType == 2 && !isDetails && $route.query.detailStatus == 0">操作</li>
                            <li
                                v-if="form.type == 2 && form.skuType == 1 && !isDetails && ($route.query.detailStatus == 0 || !$route.query.id)">
                                操作</li>

                        </ul>
                        <a-divider />
                        <div v-if="skuList.length > 0">
                            <!-- <ul class="table-tit" v-for="(item, index) in skuList" :key="index"> -->

                            <div v-for="(item, index) in skuList" :key="index">
                                <ul class="table-tit">
                                    <li style="display:flex;justify-content: center;">
                                        <a-upload :beforeUpload="file => beforeUploadBannerPic(file, index)"
                                            v-if="!item.pic && form.type == 1"
                                            @change="info => uploadSkuPicChange(info, index)" list-type="picture-card"
                                            class="avatar-uploader" :multiple="false" :data="postData"
                                            :action="qiNiuAction">
                                            <template>
                                                <div>
                                                    <a-icon type="plus" />
                                                </div>
                                            </template>
                                        </a-upload>
                                        <div v-else
                                            style="width:80px;height:80px;border:1px solid #eee; padding:10px ;box-sizing: border-box;position:relative;display:flex;">
                                            <img :src="item.pic" alt=""
                                                style="max-height:60px;max-width:60px;margin:auto;cursor: pointer;"
                                                @click="openImages(item.pic)">
                                            <a-icon v-if="!$route.query.isDetails && form.type == 1"
                                                @click="delSkuPic(index)" type="close-circle"
                                                style="position:absolute;right:0;top:0;cursor: pointer;" />
                                        </div>
                                    </li>
                                    <li>
                                        <!-- 新增 -->
                                        <a-input v-if="!isDetails && !$route.query.id"
                                            v-model="item.specsAttributeValue"
                                            :disabled="form.type == 2 || form.skuType == 2 || ($route.query.id && $route.query.detailStatus != 0)" placeholder="规格" />
                                        <!-- 编辑 -->
                                        <a-input v-if="!isDetails && $route.query.id" v-model="item.specsAttributeValue"
                                            :disabled="form.type == 2 || form.skuType == 2 || ($route.query.id && $route.query.detailStatus != 0)" placeholder="规格" />
                                        <!-- 详情 -->
                                        <a-input v-if="isDetails" v-model="item.specsAttribute" disabled
                                            placeholder="规格" />

                                    </li>
                                    <li>
                                        <a-input
                                            :disabled="isDetails || form.type == 2 || ($route.query.id && $route.query.detailStatus != 0)"
                                            v-model="item.barCode" placeholder="条形码" />
                                    </li>
                                    <li>
                                        <a-input :disabled="isDetails || form.type == 2" v-model="item.supplierCode"
                                            placeholder="供应商自查sku编码" />
                                    </li>
                                    <li>
                                        <!-- <a-input :disabled="isDetails" v-model="item.marketPrice" placeholder="市场建议零售价" /> -->
                                        <a-input v-if="form.type == 1" v-model="item.marketPrice"
                                            :disabled="isDetails && $route.query.detailStatus != 2"
                                            placeholder="市场建议零售价" />
                                        <a-input v-if="form.type == 2" v-model="item.plfPrice"
                                            :disabled="isDetails && $route.query.detailStatus != 2"
                                            placeholder="市场建议零售价" />
                                    </li>
                                    <li>
                                        <!-- <a-input :disabled="isDetails" v-model="item.dfPrice" placeholder="含税运代发成本" /> -->
                                        <a-input v-if="form.type == 1" v-model="item.dfPrice"
                                            :disabled="isDetails && $route.query.detailStatus != 2"
                                            placeholder="含税运代发成本" />
                                        <a-input v-if="form.type == 2" v-model="item.price" disabled
                                            placeholder="含税运代发成本" />
                                    </li>
                                    <li>
                                        <!-- <a-input :disabled="isDetails" v-model="item.jcCount" placeholder="采集数量" /> -->
                                        <a-input v-model="item.jcCount" :disabled="form.type == 2 || isDetails"
                                            placeholder="采集数量" />
                                    </li>
                                    <li>
                                        <a-input :disabled="isDetails || form.type == 2" v-model="item.jcPrice"
                                            placeholder="含税运集采成本" />
                                    </li>
                                    <!-- <li>
                                        <a-input :disabled="isDetails || form.type == 2" v-model="item.stock"
                                            placeholder="库存" />
                                    </li> -->
                                    <li v-if="form.type == 2">
                                        <a-input-number style="width:100%" :disabled="isDetails" v-model="item.skuCount"
                                            placeholder="数量" />
                                    </li>
                                    <li v-if="form.type == 1">
                                        <a-button type="primary" v-if="item.warehouseId"
                                            @click="selCk(index)">查看仓库</a-button>
                                        <a-button v-else @click="selCk(index)">选择仓库</a-button>
                                    </li>
                                    <li v-if="form.type == 1">
                                        <a-button type="primary" v-if="item.referencePriceList.length > 0"
                                            @click="selCKJ(index)">查看</a-button>
                                        <a-button v-else @click="selCKJ(index)">添加</a-button>
                                    </li>
                                    <li v-if="form.type == 1">
                                        <a-button type="primary" v-if="item.stockUpDay"
                                            @click="selGH(index)">查看</a-button>
                                        <a-button v-else @click="selGH(index)">添加</a-button>
                                    </li>
                                    <li v-if="form.skuType == 2 && !isDetails && $route.query.detailStatus == 0">
                                        <a @click="delSku(index)" style="margin-left: 5px;" v-if="index > 0">删除</a>
                                    </li>
                                    <li v-if="form.type == 2 && !isDetails && ($route.query.detailStatus == 0 || !$route.query.id)">
                                        <a @click="delSku(index)" style="margin-left: 10px;color:red">删除</a>
                                    </li>
                                </ul>
                                <!-- <div style="display:flex;">
                                    <div v-for="goods in item.goodsSpecPackageList" :key="goods.packageGoodsSpecId"
                                        style="border:1px solid #eee;display:flex;align-items:center;padding:10px;border-radius: 10px;margin-right:10px">
                                        <img :src="goods.pic"
                                            style="width:60px;height:60px;border:1px solid #eee;padding:8px;margin-right:20px;border-radius:6px"
                                            alt="">
                                        <div>
                                            <p>{{ goods.name }}</p>
                                            <span>市场建议零售价：¥</span>
                                            <span style="margin-right:10px;display:inline-block;color:#000">{{
            goods.marketPrice
        }}</span>
                                            <span>含税运代发成本价：¥</span>
                                            <span style="color:#000;font-size:15px">{{ goods.dfPrice }}</span>
                                            <span style="margin-left:15px;color:#000;font-size:15px">x{{ goods.skuCount
                                                }}</span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>

                        </div>
                        <div v-if="skuList.length == 0">
                            <a-empty />
                        </div>
                    </div>
                </div>
            </div>

            <!-- 品牌资质 -->
            <div class="box" v-if="form.type == 1">
                <span class="box-tit">品牌资质 <span v-if="form.goodsQualification.goodsIsImport == 1"
                        style="color:red;font-size:13px">报关单/入境检验检疫证明，最少填写一个完整的信息。</span></span>
                <a-form-model ref="ruleForm" :label-col="{ span: 5 }">
                    <a-row style="margin-top:10px;display: flex;width:100%;">
                        <a-form-model-item label="是否进口商品" required style="width:500px;">
                            <a-radio-group v-model="form.goodsQualification.goodsIsImport" @change="onJKGoods"
                                :disabled="isDetails">
                                <a-radio :value="0">否</a-radio>
                                <a-radio :value="1">是</a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item label="选择品牌" required style="width:400px;">
                            <div style="display: flex;align-items: center;">
                                <a-select show-search :value="searchVal" placeholder="搜索品牌名称"
                                    :disabled="isDetails || ($route.query.id && $route.query.detailStatus != 0)"
                                    :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                                    :not-found-content="null" @search="fetchUser" @change="handleSearchChange">
                                    <a-select-option v-for="d in brandList" :key="d.name" :label="d.name" :value="d.id">
                                        {{ d.name }}
                                    </a-select-option>
                                </a-select>
                            </div>
                        </a-form-model-item>

                    </a-row>
                </a-form-model>
                <a-card v-if="form.goodsQualification.goodsIsImport == 0" title="产品质检报告"
                    style="width: 30%;border:1px solid #eee !important;border-radius:10px;">
                    <a-row>
                        <a-col>
                            <a-form-model-item required label="文件" :labelCol="{ span: 4 }"
                                :wrapperCol="{ span: 18, offset: 1 }">
                                <a-upload name="file" :accept="'.pdf,.png,.jpg,.jpeg,pdf'" :multiple="true"
                                    :disabled="$route.query.isDetails" :file-list="moreFileList" :action="qiNiuAction"
                                    :data="postData" @change="uploadMoreFile">
                                    <a-button v-if="!$route.query.isDetails">
                                        <a-icon type="upload" /> 上传文件
                                    </a-button>
                                    <span style="color:red;margin-left:5px"
                                        v-if="!$route.query.isDetails">(支持.png/.jpg/.pdf格式)</span>
                                </a-upload>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-form-model-item label="生效时间" required :labelCol="{ span: 5 }"
                            :wrapperCol="{ span: 18, offset: 1 }">
                            <a-date-picker v-model="dateStartTime" @change="onStartChange" :disabled="isDetails" />
                        </a-form-model-item>
                    </a-row>
                    <a-row>
                        <a-form-model-item label="失效时间" required :labelCol="{ span: 5 }"
                            :wrapperCol="{ span: 18, offset: 1 }">
                            <a-date-picker style="margin-right: 10px;" v-model="dateEndTime" :disabled="isDetails"
                                @change="onEndChange" />
                        </a-form-model-item>
                    </a-row>
                </a-card>
                <div style="display:flex">
                    <a-card v-if="form.goodsQualification.goodsIsImport == 1" title="报关单"
                        style="width: 30%;border:1px solid #eee !important;border-radius:10px;">
                        <a-row>
                            <a-col>
                                <a-form-model-item required label="文件" :labelCol="{ span: 4 }"
                                    :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-upload name="file" :accept="'.pdf,.png,.jpg,.jpeg,pdf'" :multiple="false"
                                        v-if="!fileObj0.goodsQualificationUrl" :disabled="isDetails"
                                        :action="qiNiuAction" :data="postData" @change="uploadFileZMone">
                                        <a-button :disabled="isDetails">
                                            <a-icon type="upload" /> 上传文件
                                        </a-button>
                                        <span style="color:red;margin-left:5px">(支持.png/.jpg/.pdf格式)</span>
                                    </a-upload>
                                    <div v-else>
                                        <a :href="fileObj0.goodsQualificationUrl" style="margin-left: 5px;"
                                            target="_blank">预览文件</a>
                                        <a @click="removeFileObj(0)"
                                            v-if="$route.query.detailStatus == 0 || !$route.query.id"
                                            style="margin-left: 5px;color:red;">删除</a>
                                    </div>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-form-model-item required label="生效时间" :labelCol="{ span: 5 }"
                                :wrapperCol="{ span: 18, offset: 1 }">
                                <a-date-picker v-model="dateStartTime" :disabled="isDetails" @change="onStartChange" />
                            </a-form-model-item>
                        </a-row>
                        <a-row>
                            <a-form-model-item required label="失效时间" :labelCol="{ span: 5 }"
                                :wrapperCol="{ span: 18, offset: 1 }">
                                <a-date-picker style="margin-right: 10px;" v-model="dateEndTime" :disabled="isDetails"
                                    @change="onEndChange" />
                            </a-form-model-item>
                        </a-row>
                    </a-card>

                    <a-card v-if="form.goodsQualification.goodsIsImport == 1" title="入境检验检疫证明"
                        style="width: 30%;border:1px solid #eee !important;border-radius:10px;">
                        <a-row>
                            <a-col>
                                <a-form-model-item label="文件" :labelCol="{ span: 4 }"
                                    :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-upload name="file" :accept="'.pdf,.png,.jpg,.jpeg,pdf'" :multiple="false"
                                        v-if="!fileObj1.goodsQualificationUrl" :disabled="isDetails"
                                        :action="qiNiuAction" :data="postData" @change="uploadFileZMtwo">
                                        <a-button :disabled="isDetails">
                                            <a-icon type="upload" /> 上传文件
                                        </a-button>
                                        <span style="color:red;margin-left:5px">(支持.png/.jpg/.pdf格式)</span>
                                    </a-upload>
                                    <div v-else>
                                        <a :href="fileObj1.goodsQualificationUrl" style="margin-left: 5px;"
                                            target="_blank">预览文件</a>
                                        <a @click="removeFileObj(1)" style="margin-left: 5px;color:red;"
                                            v-if="$route.query.detailStatus == 0 || !$route.query.id">删除</a>
                                    </div>

                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-form-model-item label="生效时间" :labelCol="{ span: 5 }"
                                :wrapperCol="{ span: 18, offset: 1 }">
                                <a-date-picker v-model="dateStartTime1" @change="onStartChange1"
                                    :disabled="isDetails" />
                            </a-form-model-item>
                        </a-row>
                        <a-row>
                            <a-form-model-item label="失效时间" :labelCol="{ span: 5 }"
                                :wrapperCol="{ span: 18, offset: 1 }">
                                <a-date-picker style="margin-right: 10px;" v-model="dateEndTime1" :disabled="isDetails"
                                    @change="onEndChange1" />
                            </a-form-model-item>
                        </a-row>
                    </a-card>
                </div>

            </div>

            <!-- 图片信息 -->
            <span style="color: #000;font-size: 15px;font-weight: 500;">图片信息</span>
            <!-- 品牌资质 -->
            <div class="box min1538">
                <span class="box-tit">商品展示图</span>
                <p style="margin-top:10px;">
                    <a-upload :multiple="true" :data="postData" :action="qiNiuAction" :show-upload-list="false"
                        :accept="'.jpg,.jpeg,.png'" @change="uploadMorePic">
                        <a-button style="margin-right: 10px;" :loading="uploadIng" type="primary"
                            v-if="!$route.query.isDetails">一键上传多图</a-button>
                        <span>标记 * 为必填图片信息，拖拽图片可更换位置。800*800像素，72分辨率；需要透明背景的主图；礼包/组合的产品图片需对应产品规格，图片以商品名称命名</span>
                    </a-upload>
                </p>
                <div style="display:flex;">
                    <p style="text-align:center;width:100px;margin: 0 10px;" v-for="item in 10" :key="item">
                        <span style="color:red;" v-if="item - 1 <= 2">*</span>
                        <span v-if="item - 1 > 0">{{ getPicTitle(item - 1) }}</span>
                        <span v-else>商品正面图 白底 <span style="color:red;">为主图</span></span>
                    </p>
                </div>
                <ul class="box-img-list" style="padding:0;">
                    <draggable v-model="bannerPicList">
                        <transition-group>
                            <li v-for="(item, index) in bannerPicList" :key="index"
                                style="width:100px;margin-right:20px;">
                                <a-upload :beforeUpload="file => beforeUploadBannerPic(file, index)" v-if="!item"
                                    :disabled="$route.query.isDetails" :show-upload-list="false"
                                    @change="info => uploadBannerPicChange(info, index)" :accept="'.jpg,.jpeg,.png'"
                                    list-type="picture-card" :multiple="false" :data="postData" :action="qiNiuAction">
                                    <template>
                                        <div>
                                            <a-icon type="plus" />
                                        </div>
                                    </template>
                                </a-upload>
                                <div v-else
                                    style="width:100px;height:100px;border:1px solid #eee; padding:10px;box-sizing: border-box;position:relative;">
                                    <img :src="item" alt="" style="width:100%;height:100%;cursor: pointer;"
                                        @click="openImages(item)">
                                    <a-icon @click="delBannerPic(index)" type="close-circle"
                                        v-if="!$route.query.isDetails"
                                        style="position:absolute;right:0;top:0;cursor: pointer;" />
                                </div>
                            </li>
                        </transition-group>
                    </draggable>
                </ul>
            </div>

            <!-- 商品描述 -->
            <div class="box box-ms">
                <span class="box-tit" style="margin-right:10px;display:inline-block">商品描述</span>
                <a-upload :multiple="true" :data="postData" :action="qiNiuAction" :show-upload-list="false"
                    :accept="'.jpg,.jpeg,.png'" @change="uploadMorePicDetails">
                    <a-button style="margin-right: 10px;" :loading="uploadIng2" type="primary"
                        v-if="!$route.query.isDetails">一键上传多图</a-button>
                </a-upload>
                <div>宽度需≥750≤790，长度不限，图片不小于1500kb，图片格式为.jpg、.png，最多不可超过20张图 <span style="color:red">最少不小于1张</span></div>
                <div>根据列表顺序展示详情图，拖拽图片可更换位置</div>
                <div>不得含有广告禁止语，例如“最大、最好、世界第一、唯一…”，不能含有广告法禁止的功效宣传语“有利于减肥、美白、美颜、补血”，不虚假宣传、实事求是，合理的表达商品</div>
                <div>卖点、特色、差异化、核心卖点，提升消费者购买欲望。</div>

                <ul class="box-img-list2">
                    <draggable v-model="detailPicList">
                        <transition-group>
                            <li v-for="(item, index) in detailPicList" :key="index">
                                <a-upload :beforeUpload="file => beforeUploadBannerPic(file, index)" v-if="!item"
                                    :disabled="$route.query.isDetails" :accept="'.jpg,.jpeg,.png'"
                                    @change="info => uploadBannerPicDetailsChange(info, index)" list-type="picture-card"
                                    :multiple="false" :data="postData" :action="qiNiuAction">
                                    <template>
                                        <div>
                                            <a-icon type="plus" />
                                        </div>
                                    </template>
                                </a-upload>

                                <div v-else
                                    style="width:100px;height:100px;border:1px solid #eee; padding:10px;box-sizing: border-box;position:relative;">
                                    <img :src="item" alt="" style="width:100%;height:100%;cursor:pointer;"
                                        @click="openImages(item)">
                                    <a-icon @click="delDetailBannerPic(index)" type="close-circle"
                                        v-if="!$route.query.isDetails"
                                        style="position:absolute;right:0;top:0;cursor: pointer;" />
                                </div>
                            </li>
                        </transition-group>
                    </draggable>
                </ul>
            </div>

            <div style="display:flex; justify-content: center;margin-top:40px;" v-if="!this.$route.query.isDetails">
                <a-button type="primary" style="margin-right: 10px;" @click="handleRules(1)"
                    v-if="!$route.query.detailStatus || $route.query.detailStatus == 0">保存为草稿</a-button>
                <a-button type="primary" @click="handleRules(2)">保存并提交</a-button>
            </div>
        </div>
        <!-- 仓库选择 -->
        <a-modal v-model="ckOpen" @ok="handleCk">
            <div style="display:flex">
                <span class="ant-modal-title" style="margin-bottom:20px">仓库选择</span>
                <div style="width: 42px;text-align: right;">
                    <a-tooltip>
                        <template slot="title">
                            <span>仓库资质为审核通过时，则可以进行选择。</span>
                        </template>
                        <span style="font-size: 13px;">
                            <a-icon type="question-circle" />
                            <span style="color: #e6b915;">说明</span>
                        </span>
                    </a-tooltip>
                </div>
            </div>
            <a-select v-model="selectCkId" style="width: 220px;margin-right: 10px;" @change="handleChangeCK"
                :disabled="$route.query.isDetails" placeholder="选择仓库">
                <a-select-option :value="item.id" v-for="item in ckList" :key="item.id">
                    {{ item.name }}
                </a-select-option>
            </a-select>
        </a-modal>

        <!-- 供货信息 -->
        <a-modal v-model="ghOpen" title="供货信息" :footer="null" :maskClosable="false" :closable="false">
            <a-form-model v-if="skuList.length > 0">
                <a-form-model-item label="供货周期" required>
                    <a-range-picker :disabledDate="disabledDate" :value="supplyTime" :disabled="$route.query.isDetails"
                        :allowClear="false" @change="supplyChange" />
                </a-form-model-item>
                <a-form-model-item label="备货周期" required>
                    <a-input style="width:100px;margin-right:10px" :disabled="$route.query.isDetails"
                        v-model="stockUpDay" />天
                </a-form-model-item>
            </a-form-model>
            <div style="display:flex;justify-content: center;">
                <a-button @click="handleGH" type="primary">确定</a-button>
                <a-button @click="ghOpen = false" style="margin-left:10px">取消</a-button>
            </div>
        </a-modal>


        <!-- 添加参考价 -->
        <!-- <a-modal v-model="CKJOpen" title="添加参考价" :width="800" :footer="null" :maskClosable="false" :closable="false"> -->
        <a-modal v-model="CKJOpen" title="添加参考价" :width="800" :footer="null" :maskClosable="false" :closable="false">
            <div v-if="skuList.length > 0">
                <span style="color:red;">*以下四类必须完成一列</span>
                <!-- <div v-for="(item, index) in skuList[skuListSelectIndex].referencePriceList" :key="index"> -->
                <div v-for="(item, index) in referencePriceList" :key="index">
                    <div class="box" v-if="item.type == 1">
                        <span class="box-tit">京东</span>
                        <a-form-model style="display:flex;">
                            <a-form-model-item label="京东参考售价" style="flex:1;margin-right:10px">
                                <a-input :disabled="$route.query.isDetails" v-model="item.price" />
                            </a-form-model-item>
                            <a-form-model-item label="京东参考链接" style="flex:1">
                                <a-input :disabled="$route.query.isDetails" v-model="item.url" />
                            </a-form-model-item>
                        </a-form-model>
                    </div>
                    <div class="box" v-if="item.type == 2">
                        <span class="box-tit">天猫</span>
                        <a-form-model style="display:flex;">
                            <a-form-model-item label="天猫参考售价" style="flex:1;margin-right:10px">
                                <a-input :disabled="$route.query.isDetails" v-model="item.price" />
                            </a-form-model-item>
                            <a-form-model-item label="天猫参考链接" style="flex:1">
                                <a-input :disabled="$route.query.isDetails" v-model="item.url" />
                            </a-form-model-item>
                        </a-form-model>
                    </div>
                    <div class="box" v-if="item.type == 3">
                        <span class="box-tit">其他</span>
                        <a-form-model style="display:flex;">
                            <a-form-model-item label="其他渠道参考售价" style="flex:1;margin-right:10px">
                                <a-input :disabled="$route.query.isDetails" v-model="item.price" />
                            </a-form-model-item>
                            <a-form-model-item label="其他渠道参考链接" style="flex:1">
                                <a-input :disabled="$route.query.isDetails" v-model="item.url" />
                            </a-form-model-item>
                        </a-form-model>
                    </div>
                    <div class="box" v-if="item.type == 4">
                        <span class="box-tit">线下</span>
                        <a-form-model style="display:flex;">
                            <a-form-model-item label="线下售价" style="flex:1;margin-right:10px">
                                <a-input :disabled="$route.query.isDetails" v-model="item.price" />
                            </a-form-model-item>
                            <a-form-model-item label="线下售价证明" style="flex:1">
                                <a-upload v-if="!item.url" :disabled="$route.query.isDetails"
                                    @change="info => uploadChange(info, index)" list-type="picture-card"
                                    :multiple="false" :data="postData" :action="qiNiuAction">
                                    <template>
                                        <div>
                                            <a-icon type="plus" />
                                        </div>
                                    </template>
                                </a-upload>
                                <div v-else
                                    style="width:100px;height:100px;border:1px solid #eee; padding:10px;box-sizing: border-box;position:relative;">
                                    <img :src="item.url" alt="" style="width:100%;height:100%;">
                                    <a-icon v-if="!$route.query.isDetails" @click="delDownUrl(index)"
                                        type="close-circle" style="position:absolute;right:0;top:0;cursor: pointer;" />
                                </div>
                            </a-form-model-item>
                        </a-form-model>


                    </div>
                </div>
            </div>
            <div style="display:flex;justify-content: center;">
                <a-button @click="handleCKJ" type="primary">确定</a-button>
                <a-button @click="CKJOpen = false" style="margin-left:10px">取消</a-button>
            </div>

        </a-modal>

        <!-- 添加子品handleGoods -->
        <a-modal v-model="goodsOpen" title="添加子品" @ok="handleGoodsConfirm" :width="1000">
            <a-form-model style="display:flex;">
                <a-form-model-item label="商品名称">
                    <a-input v-model="queryData.name" placeholder="请输入商品名称" />
                </a-form-model-item>
                <a-form-model-item label="供应商自查sku编码" style="margin:0 20px;">
                    <a-input v-model="queryData.supplierCode" placeholder="请输入供应商自查sku编码" />
                </a-form-model-item>
                <a-form-model-item label="品类" style="width:280px">
                    <!-- <a-cascader v-model="classifyList" :options="categoryOptions" :load-data="getCategoryList"
                        placeholder="请选择品类" change-on-select @change="categoryChange" /> -->
                    <a-cascader v-model="classifyList" :options="categoryOptions" :load-data="getCategoryList"
                        placeholder="请选择品类" change-on-select @change="categoryChange" />
                </a-form-model-item>
                <a-form-model-item style="margin-top:40px;">
                    <a-button @click="resetGoods" style="margin-left: 10px;">重置</a-button>
                    <a-button type="primary" style="margin-top: 4px;margin-left: 10px;" @click="getGoods">查询</a-button>
                </a-form-model-item>

            </a-form-model>
            <a-table :columns="columns" ref="tabled" :dataSource="dataSource" :loading="tableLoading"
                :row-selection="rowSelection" :pagination="false">
                <template slot="pic" slot-scope="record">
                    <img :src="record" style="width: 40px;max-height: 40px;" alt="">
                </template>
                <template slot="createTime" slot-scope="record">{{ formatDateMin(record) }}</template>
            </a-table>
            <div class="page-box">
                <a-pagination :total="total" :current="queryData.page + 1" :pageSize="queryData.size"
                    :page-size-options="pageSizeOptions" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                    show-size-changer />
            </div>
        </a-modal>

        <!-- 确认子品 -->
        <!-- <a-modal v-model="openGoodsConfirm" title="确认子品" @ok="handleGoodsConfirm" :width="1100">
            <a-button style="margin-bottom:20px;" @click="goodsOpen = true; openGoodsConfirm = false">增加子品</a-button>
            <a-table :columns="columns2" :dataSource="dataSource2" :loading="tableLoading" :pagination="false">
                <template slot="skuCount" slot-scope="text, record">
                    <a-input v-model="record.skuCount" />
                </template>
                <template slot="dfPrice" slot-scope="text, record">
                    <a-input v-model="record.dfPrice" />
                </template>

                <template slot="pic" slot-scope="record">
                    <img :src="record" style="width: 40px;max-height: 40px;" alt="">
                </template>
                <template slot="createTime" slot-scope="record">{{ formatDateMin(record) }}</template>
                <template slot="action" slot-scope="text,record">
                    <a @click="delGoodsConfirm(record)">删除</a>
                </template>
            </a-table>
        </a-modal> -->
        <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>

    </div>
</template>

<script>
import draggable from 'vuedraggable'


import moment from 'moment';
import { request } from '@/utils/request'
import debounce from 'lodash/debounce';
import { EventBus } from '@/utils/event-bus.js';
export default {
    components: { draggable },
    data() {
        this.lastFetchId = 0;
        this.fetchUser = debounce(this.fetchUser, 900);
        return {
            columns: [
                { title: '商品图片', dataIndex: 'pic', align: 'center', scopedSlots: { customRender: 'pic' }, width: 70 },
                { title: '商品名称', dataIndex: 'name', align: 'center', width: 100 },
                { title: '供应商自查sku编码', dataIndex: 'supplierCode', align: 'center', width: 80 },
                { title: '商品含税运代发成本', dataIndex: 'dfPrice', align: 'center', width: 70 },
                { title: '商品品类', dataIndex: 'classify', align: 'center', width: 120 },
                { title: '创建时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' }, width: 120 },
            ],
            list: [
                { id: 1, name: "Java" },
                { id: 2, name: "Python" },
                { id: 3, name: "PHP" },
                { id: 4, name: "C" }
            ],
            columns2: [
                { title: '商品图片', dataIndex: 'pic', align: 'center', scopedSlots: { customRender: 'pic' }, width: 70 },
                { title: '商品名称', dataIndex: 'name', align: 'center', width: 100 },
                { title: '供应商自查sku编码', dataIndex: 'supplierCode', align: 'center', width: 80 },
                { title: '商品品类', dataIndex: 'classify', align: 'center', width: 120 },
                { title: '组合内单品数量', dataIndex: 'skuCount', align: 'center', width: 120, scopedSlots: { customRender: 'skuCount' } },

                { title: '商品售价', dataIndex: 'marketPrice', align: 'center', width: 70 },
                { title: '商品含税运代发成本', dataIndex: 'dfPrice', align: 'center', width: 70, scopedSlots: { customRender: 'dfPrice' } },
                { title: '创建时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' }, width: 120 },
                { title: '操作', dataIndex: 'action', align: 'center', scopedSlots: { customRender: 'action' }, width: 70 },
            ],
            dataSource: [],
            expandedRowKeys: [],
            selectedRowKeys: [], // Initialize selectedRowKeys           
            form: {
                classifyList: [],
                // returnable: 1,//7天
                type: 1,
                skuType: 1,//规格类型
                goodsQualification: {
                    goodsIsImport: 0,
                    goodsQualificationFileList: []
                }
            },
            skuList: [{
                pic: "",//规格图
                specsAttributeValue: "",//规格属性值
                specsAttributeId: "",//规格属性ID
                barCode: "",//条形码
                supplierCode: "",//供应商编号
                marketPrice: "",// 市场建议零售价
                dfPrice: "",//含税运代发成本
                jcCount: "",//集采数量
                jcPrice: "",//含税运集采成本
                // stock: "",//库存
                skuCount: 1,// 数量
                warehouseId: "",//仓库id
                warehouseName: "",
                referencePriceList: [],//参考售价
                stockUpDay: 1
            }],
            qiNiuAction: 'https://upload-z1.qiniup.com',
            qiNiuUploadData: {
                key: '',
                token: ''
            },
            getQiniuTokenIng: false,
            postData: {
                token: ''
            },
            fileList: [],

            // 选择仓库
            ckOpen: false,
            skuListSelectIndex: 0,  //skuList当前选中几条
            selectedIndex: 0,
            ckList: [],
            selectCkId: null,//已选中仓库

            // 参考售价
            CKJOpen: false,
            pageSizeOptions: [10, 30, 50, 100, 500, 1000],

            // 上传报关文件
            dateStartTime: null,
            dateEndTime: null,
            uploadfileList: [],
            uploadfileList1: [],

            // 品牌搜索
            brandList: [],
            searchVal: null,

            // 商品详情顶图
            bannerPicList: ["", "", "", "", "", "", "", "", "", "",],
            fileLists: [], // 用于存储每个条目的文件列表
            fileListdetailPic: [],
            detailPicList: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],//详情图

            classifyList2: [],
            classifyList3: [],
            classifyList4: [],
            cateGoryType: "",

            // 分类id
            twoId: [],
            threeId: [],
            fourId: [],

            // 供货
            ghOpen: false,
            supplyTime: null,//供货时间


            skuFileList: [],

            addSkuList: [""],
            skuWrapList: [{ name: "", children: [] }],
            moment,

            tableLoading: false,
            queryData: {
                page: 0,
                size: 10,
                auditStatus: 2
            },
            total: 0,
            goodsOpen: false,//商品选择弹窗
            openGoodsConfirm: false,//确认商品弹窗
            dataSource2: [],
            selDfPrice: 0,
            selMarketPrice: 0,

            pageCurrent: 0,

            classifyDesc: "",
            isDetails: false,

            nextShow: false,

            uploadIng: false,
            uploadIng2: false,

            textBanner: [{ id: 1, positionNum: 2, name: "测试卡片2", }, { id: 2, positionNum: 3, name: "测试卡片3", }],
            twoName: "",
            threeName: "",
            fourName: "",

            // 品类
            categoryOptions: [{ value: '1', label: '商城', isLeaf: false }],
            classifyList: [],
            dateEndTime1: "",
            dateStartTime1: "",

            fileObj0: {
                certificateType: 402,
                goodsQualificationUrl: "",
                goodsQualificationStartTime: "",
                goodsQualificationEndTime: ""
            },
            fileObj1: {
                certificateType: 403,
                goodsQualificationUrl: "",
                goodsQualificationStartTime: "",
                goodsQualificationEndTime: ""
            },


            referencePriceList: [],//临时数组
            stockUpDay: "",
            supplyStartTime: "",
            supplyEndTime: "",
            moreFileList: [],
            previewVisible: false,  // 控制预览弹框的显示
            previewImage: "",
            deliveryScopeIdList:[]

        }
    },
    mounted() {
        this.getQiniuToken();
        this.getInitData();
    },
    computed: {
        rowSelection() {
            return {
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys = selectedRowKeys; // Update selected row keys
                    console.log(this.selectedRowKeys, 'this.selectedRowKeys')
                    console.log(this.dataSource2, 'dataSource2')
                    this.dataSource2 = selectedRows;
                    this.dataSource2.forEach((item, index) => {
                        this.selectedRowKeys.forEach((it, idx) => {
                            if (index == idx) {
                                item.key = it
                            }
                        })
                    })
                },
                onSelect: (record, selected, selectedRows) => {
                    console.log(record, selected, selectedRows);
                    this.dataSource2 = selectedRows;
                },
                onSelectAll: (selected, selectedRows, changeRows) => {
                    this.dataSource2 = selectedRows;
                    console.log(selected, selectedRows, changeRows);
                },
                expandedRowKeys: this.expandedRowKeys,
            };
        }
    },
    methods: {
        // 预览图片
        openImages(url) {
            this.previewImage = url;
            this.previewVisible = true;
        },
        getToday() {
            // 获取今天的日期，不包含时间部分
            return new Date(new Date().setHours(0, 0, 0, 0));
        },
        disabledDate(current) {
            // 不允许选择今天之前的日期
            return current && current < this.getToday();
        },
        handleRemove() {
            this.uploadFileList = []
            this.form.goodsQualification.goodsQualificationFileList[0].goodsQualificationUrl = ""
        },
        handleRemove1() {
            this.uploadFileList1 = []
            this.form.goodsQualification.goodsQualificationFileList[1].goodsQualificationUrl = ""
            this.$set(this.form.goodsQualification.goodsQualificationFileList[1], 'goodsQualificationUrl', ''); // 使用 $set 确保响应式

            this.$nextTick(() => {
                console.log(this.form.goodsQualification)
                console.log(this.form.goodsQualification.goodsQualificationFileList[1])
                console.log(this.form.goodsQualification.goodsQualificationFileList[1].goodsQualificationUrl); // 检查是否清空
            });
        },
        categoryChange(value) {
            this.classifyList = value;
        },
        getCategoryList(value) {
            let id = value[value.length - 1].value
            let url = process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + id + '/0'
            if (id != 1) {
                url = process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + 1 + '/' + id
            }
            request(url, 'get').then((res) => {
                let data = res.data.data;
                console.log(data, 'data')
                data.forEach(item => {
                    item.value = item.id
                    item.label = item.name
                    if (item.level < 3) {
                        item.isLeaf = false
                    }
                })
                if (value.length != 3) {
                    if (id == 1) {
                        this.categoryOptions.forEach(item => {
                            if (item.value == id) {
                                item.children = data;
                            }
                        })
                    } else {
                        this.categoryOptions[0].children.forEach(item => {
                            if (item.value == id) {
                                item.children = data;
                            }
                        })
                    }
                } else {
                    this.categoryOptions[0].children.forEach(item => {
                        if (item.id == value[1].id) {
                            item.children.forEach(it => {
                                if (it.id == value[2].id) {
                                    it.children = data;
                                }
                            })
                        }
                    })
                }

            })
        },
        // getCategoryList(value) {
        //     let id = value[value.length - 1].value
        //     if (id == 1) {
        //         id = 0
        //     }
        //     request(process.env.VUE_APP_API_BASE_URL + 'srm/goods/v2/classifylist', 'post', {
        //         page: 0, size: 1000, type: 1, pId: id,auditStatus:2
        //     }).then((res) => {
        //         let data = res.data.data.data;
        //         data.forEach(item => {
        //             item.value = item.classifyId
        //             item.label = item.classifyName
        //             if (item.level < 3) {
        //                 item.isLeaf = false
        //             }
        //         })
        //         if (id == 0) {
        //             this.categoryOptions[0].children = data;
        //         }
        //         if (value.length != 3) {
        //             if (id == 1) {
        //                 this.categoryOptions.forEach(item => {
        //                     if (item.value == id) {
        //                         item.children = data;
        //                     }
        //                 })
        //             } else {
        //                 this.categoryOptions[0].children.forEach(item => {
        //                     if (item.value == id) {
        //                         item.children = data;
        //                     }
        //                 })
        //             }
        //         } else {
        //             console.log(this.categoryOptions, 'this.categoryOptions')
        //             this.categoryOptions[0].children.forEach(item => {
        //                 if (item.id == value[1].id) {
        //                     if (item.children && item.children.length > 0) {
        //                         item.children.forEach(it => {
        //                             if (it.id == value[2].id) {
        //                                 it.children = data;
        //                             }
        //                         })
        //                     }

        //                 }
        //             })
        //         }

        //     })
        // },
        onEnd(event) {
            // Handle the end of the drag event if needed
            console.log('Drag ended:', event);
        },
        // 添加子品重置
        resetGoods() {
            this.queryData = {
                page: 0,
                size: 10,
                auditStatus: 2
            }
            this.classifyList = []
            this.getGoods();
        },
        next() {
            this.form.classifyList = [this.twoId, this.threeId, this.fourId]
            this.pageCurrent = 1
        },
        // 删除规格图片
        delSkuPic(index) {
            this.skuList[index].pic = ""
            this.$set(this.skuList[index], 'pic', '');
        },
        // 删除展示图
        delBannerPic(index) {
            this.bannerPicList[index] = ""
            this.$set(this.bannerPicList, index, "");
        },
        // 删除描述图
        delDetailBannerPic(index) {
            this.detailPicList[index] = ""
            this.$set(this.detailPicList, index, "");
        },
        // 计算确认子品价格
        getPrice(type) {
            if (this.dataSource2.length == 0) {
                return 0;
            }
            if (type == 0) {
                let price = 0;
                this.dataSource2.forEach(item => {
                    if (item.skuCount) {
                        price += item.skuCount * item.marketPrice;
                    }
                });
                this.selMarketPrice = price.toFixed(2);
                console.log(this.selMarketPrice, 'this.selMarketPrice')
                return this.selMarketPrice;
            }
            if (type == 1) {
                let price = 0;
                this.dataSource2.forEach(item => {
                    if (item.skuCount) {
                        price += item.skuCount * item.dfPrice;
                    }
                });
                this.selDfPrice = price.toFixed(2);
                return this.selDfPrice;
            }
        },
        getSkuListPirce(type) {
            if (type == 0) {
                let price = 0;
                this.skuList.forEach(item => {
                    console.log(item, 'item')
                    if (item.skuCount) {
                        price += item.skuCount * item.plfPrice;
                    }
                });
                this.form.plfPrice = price.toFixed(2);
                return price.toFixed(2);
            }
            if (type == 1) {
                let price = 0;
                this.skuList.forEach(item => {
                    if (item.skuCount) {
                        price += item.skuCount * item.price;
                    }
                });
                this.form.price = price.toFixed(2);
                return price.toFixed(2);
            }
        },
        // 切换单双规格
        skuTypeChange(e) {
            this.skuWrapList = [{ name: "", children: [] }]
            if (e.target.value == 1) {
                this.skuList = [{
                    pic: "",//规格图
                    specsAttributeValue: "",//规格属性值
                    specsAttributeId: "",//规格属性ID
                    barCode: "",//条形码
                    supplierCode: "",//供应商编号
                    marketPrice: "",// 市场建议零售价
                    dfPrice: "",//含税运代发成本
                    jcCount: "",//集采数量
                    jcPrice: "",//含税运集采成本
                    // stock: "",//库存
                    skuCount: 1,// 数量
                    warehouseId: "",//仓库id
                    warehouseName: "",
                    referencePriceList: [],//参考售价
                    stockUpDay: 1
                }]
            } else {
                this.skuList = []
            }

        },
        // 生成多规格产品
        // saveSkuGG() {
        //     let data = this.skuWrapList
        //     // 检查数组中的任何值是否为空
        //     const hasEmptyValue = data.some(category =>
        //         !category.name ||
        //         category.children.some(child => !child.name)
        //     );
        //     if (hasEmptyValue) {
        //         this.$message.error('请补全规格值/规格名')
        //         return false;
        //     }
        //     const generateCombinations = (data) => {
        //         const combine = (arrays) => {
        //             return arrays.reduce((acc, curr) =>
        //                 acc.flatMap(a => curr.map(b => [...a, b])),
        //                 [[]]
        //             );
        //         };
        //         const combinations = combine(data.map(cat => cat.children.map(child => child.name)));
        //         return combinations.map(combination => {
        //             const specsAttributeValue = combination.join('_');
        //             const specsAttributeId = data.map(cat => cat.name).join('_');
        //             return { specsAttributeId, specsAttributeValue };
        //         });
        //     };
        //     const output = generateCombinations(data);
        //     this.skuList = [];
        //     output.forEach(item => {
        //         this.skuList.push({
        //             pic: "",//规格图
        //             specsAttributeValue: item.specsAttributeValue,//规格属性ID
        //             specsAttributeId: item.specsAttributeId,//规格属性值
        //             barCode: "",//条形码
        //             supplierCode: "",//供应商编号
        //             marketPrice: "",// 市场建议零售价
        //             dfPrice: "",//含税运代发成本
        //             jcCount: "",//集采数量
        //             jcPrice: "",//含税运集采成本
        //             stock: "",//库存
        //             skuCount: 1,// 数量
        //             warehouseId: "",//仓库id
        //             warehouseName: "",
        //             referencePriceList: [],//参考售价
        //             stockUpDay: 1
        //         })
        //     })
        // },
        saveSkuGG() {
            let data = this.skuWrapList;

            // 检查数组中的任何值是否为空或规格名重复
            const hasEmptyValue = data.some(category =>
                !category.name ||
                category.children.some(child => !child.name)
            );

            if (hasEmptyValue) {
                this.$message.error('请补全规格值/规格名');
                return;  // 直接退出函数
            }

            // 检查规格名是否重复
            const allSpecNames = [];
            for (let category of data) {
                for (let child of category.children) {
                    if (allSpecNames.includes(child.name)) {
                        this.$message.error(`规格名 "${child.name}" 重复，请修改`);
                        return;  // 直接退出函数
                    }
                    allSpecNames.push(child.name);
                }
            }

            // 生成规格组合
            const generateCombinations = (data) => {
                const combine = (arrays) => {
                    return arrays.reduce((acc, curr) =>
                        acc.flatMap(a => curr.map(b => [...a, b])),
                        [[]]
                    );
                };
                const combinations = combine(data.map(cat => cat.children.map(child => child.name)));
                return combinations.map(combination => {
                    const specsAttributeValue = combination.join('_');
                    const specsAttributeId = data.map(cat => cat.name).join('_');
                    return { specsAttributeId, specsAttributeValue };
                });
            };

            const output = generateCombinations(data);

            // 清空 skuList 并填充新值
            this.skuList = [];
            output.forEach(item => {
                console.log(item, 'item')
                this.skuList.push({
                    pic: "", // 规格图
                    specsAttributeValue: item.specsAttributeValue, // 规格属性ID
                    specsAttributeId: item.specsAttributeId, // 规格属性值
                    // specsAttribute:item.specsAttributeId + '  ' + item.specsAttributeValue,
                    barCode: "", // 条形码
                    supplierCode: "", // 供应商编号
                    marketPrice: "", // 市场建议零售价
                    dfPrice: "", // 含税运代发成本
                    jcCount: "", // 集采数量
                    jcPrice: "", // 含税运集采成本
                    // stock: "", // 库存
                    skuCount: 1, // 数量
                    warehouseId: "", // 仓库id
                    warehouseName: "",
                    referencePriceList: [], // 参考售价
                    stockUpDay: 1
                });
            });
        },
        // 添加规格 行
        addSkuWrap() {
            this.skuWrapList.push({ name: "", children: [] })
        },
        // 添加规格
        addSkuName(index) {
            this.skuWrapList[index].children.push({ name: "" })
        },
        delSkuName(index, idx) {
            this.skuWrapList[index].children.splice(idx, 1)
        },
        delSkuRow(index) {
            this.skuWrapList.splice(index, 1)
        },
        // 选择1234级分类
        handleCategory(e) {
            this.cateGoryType = e;
            request(process.env.VUE_APP_API_BASE_URL + 'srm/goods/v2/classifylist', 'post', {
                page: 0, size: 1000, type: 1, pId: 0, auditStatus: 2
            }).then((res) => {
                if (res.data.code == 200) {
                    this.classifyList2 = res.data.data.data;
                    if (!this.$route.query.id) {
                        this.twoId = []
                        this.threeId = []
                        this.fourId = []
                        this.classifyList3 = []
                        this.classifyList4 = []
                    }
                }
            })
        },
        handleCategory2(e) {
            console.log(this.classifyList2, 'selectedItem')
            const selectedItem = this.classifyList2.find(item => item.classifyId === e);
            this.twoName = selectedItem.classifyName
            request(process.env.VUE_APP_API_BASE_URL + 'srm/goods/v2/classifylist', 'post', {
                page: 0, size: 1000, type: 1, pId: selectedItem.classifyId, auditStatus: 2
            }).then((res) => {
                if (res.data.code == 200) {
                    this.classifyList3 = res.data.data.data;
                    if (!this.$route.query.id) {
                        this.threeId = []
                        this.fourId = []
                        this.classifyList4 = []
                    }
                }
            })
        },
        handleCategory3(e) {
            const selectedItem = this.classifyList3.find(item => item.classifyId === e);
            this.threeName = selectedItem.classifyName
            request(process.env.VUE_APP_API_BASE_URL + 'srm/goods/v2/classifylist', 'post', {
                page: 0, size: 1000, type: 1, pId: selectedItem.classifyId, auditStatus: 2
            }).then((res) => {
                if (res.data.code == 200) {
                    this.classifyList4 = res.data.data.data;
                    if (!this.$route.query.id) {
                        this.fourId = []
                    }
                }
            })
        },
        handleCategory4(e) {
            const selectedItem = this.classifyList4.find(item => item.classifyId === e);
            this.fourName = selectedItem.classifyName
            this.nextShow = true;
        },
        // 保存组合商品
        handleGoods() {
            if (this.dataSource2.length == 0) {
                this.$message.error('请选择商品！')
                return false;
            }
            this.goodsOpen = false;
            this.openGoodsConfirm = true;
            // this.confirmGoods()
        },
        handleInputChange(value) {
            console.log(value, 'value')
        },
        // 确认子品列表删除
        delGoodsConfirm(record) {
            this.selectedRowKeys.forEach((item, index) => {
                if (item == record.key) {
                    this.dataSource2.splice(index, 1)
                    this.selectedRowKeys.splice(index, 1)
                }
            })
            console.log(this.selectedRowKeys, 'this.selectedRowKeys')
            // this.dataSource2.splice(index, 1)
            // this.selectedRowKeys.splice(index,1)
        },
        handleGoodsConfirm() {
            if (this.dataSource2.length == 0) {
                this.$message.error('请选择商品！')
                return false;
            }
            // let goodsSpecPackageList = [];
            for (let i = 0; i < this.dataSource2.length; i++) {
                const item = this.dataSource2[i];
                // if (!item.skuCount) {
                //     this.$message.error('组合内单品数量不可为空！')
                //     return;
                // }
                this.skuList.push({
                    pic: item.pic,//规格图
                    specsAttributeValue: item.specsAttributeValue,//规格属性ID
                    skuId: item.id,//规格属性值
                    barCode: item.barCode,//条形码
                    supplierCode: item.supplierCode,//供应商编号
                    plfPrice: item.marketPrice,// 市场建议零售价
                    price: item.dfPrice,//含税运代发成本
                    jcCount: item.jcCount,//集采数量
                    jcPrice: item.jcPrice,//含税运集采成本
                    // stock: item.stock,//库存
                    skuCount: 1,// 数量
                    // warehouseId: "",//仓库id
                    // warehouseName: "",
                    // referencePriceList: [],//参考售价
                    // goodsSpecPackageList: goodsSpecPackageList,
                    // stockUpDay: 1

                })
            }
            this.goodsOpen = false;
        },
        addGoods() {
            this.goodsOpen = true;
            this.dataSource = [];
            this.dataSource2 = [];
            this.expandedRowKeys = []
            this.selectedRowKeys = []
            this.queryData = {
                page: 0,
                size: 10,
                auditStatus: 2
            };
            this.getGoods();
        },
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getGoods()
        },
        handlePageChange(page) {
            this.expandedRowKeys = []
            this.selectedRowKeys = []
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getGoods()
        },
        getGoods() {
            if (this.classifyList.length > 0) {
                let classify = JSON.parse(JSON.stringify(this.classifyList))
                classify.splice(0, 1)
                this.queryData.classifyType = 1;
                this.queryData.classifyList = classify
            }
            // 商品规格列表
            request(process.env.VUE_APP_API_BASE_URL + 'srm/goodsSpec/list', 'post', this.queryData).then((res) => {
                this.tableLoading = false;
                if (res.data.code == 200) {
                    let data = res.data.data;
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 初始化
        getInitData() {
             // 配送规则
             request(process.env.VUE_APP_API_BASE_URL + 'srm/delivery/scope/normal/list', 'get').then((res) => {
                if (res.data.code == 200) {
                    this.deliveryScopeIdList = res.data.data;
                }
            })
            //品牌列表
            request(process.env.VUE_APP_API_BASE_URL + 'srm/brand/selectList', 'post', { page: 0, size: 10 }).then((res) => {
                if (res.data.code == 200) {
                    this.brandList = res.data.data.data;
                }
            })
            if (this.$route.query.id) {
                if (this.$route.query.detailStatus != 2 && this.$route.query.detailStatus != 0 && this.$route.query.detailStatus != 1) {
                    this.isDetails = true;
                }
                this.pageCurrent = 1
                this.fileObj0 = {}
                this.fileObj1 = {}
                request(process.env.VUE_APP_API_BASE_URL + 'srm/goodsSpec/' + this.$route.query.id, 'post', {}).then((res) => {
                    let data = res.data.data;
                    if (data.type == 1) {
                        // 回显分类
                        this.twoId = data.classifyList[0]
                        this.threeId = data.classifyList[1]
                        this.fourId = data.classifyList[2]
                        // if (this.$route.query.detailStatus == 0) {
                        if (this.$route.query.id) {
                            // 草稿状态 回显一二三级分类
                            this.handleCategory(1)
                            setTimeout(() => {
                                this.handleCategory2(this.twoId)
                            }, 300)
                            setTimeout(() => {
                                this.handleCategory3(this.threeId)
                            }, 600)
                            setTimeout(() => {
                                this.handleCategory4(this.fourId)
                            }, 1000)
                        }
                        this.skuList = data.goodsSpecList
                        this.classifyDesc = data.classifyDesc
                        this.supplyTime = [moment(data.supplyStartTime), moment(data.supplyEndTime)]
                        this.searchVal = data.brandId
                        this.fetchUser(data.brandIdDesc)

                        if (data.goodsQualification.goodsIsImport == 1) {
                            data.goodsQualification.goodsQualificationFileList.forEach(item => {
                                if (item.certificateType == 402) {
                                    this.fileObj0 = item
                                    this.dateStartTime = moment(item.goodsQualificationStartTime)
                                    this.dateEndTime = moment(item.goodsQualificationEndTime)
                                }
                                if (item.certificateType == 403) {
                                    this.fileObj1 = item
                                    this.dateStartTime1 = moment(item.goodsQualificationStartTime)
                                    this.dateEndTime1 = moment(item.goodsQualificationEndTime)
                                }
                            })
                        } else {
                            this.dateStartTime = moment(data.goodsQualification.goodsQualificationFileList[0].goodsQualificationStartTime)
                            this.dateEndTime = moment(data.goodsQualification.goodsQualificationFileList[0].goodsQualificationEndTime)
                            this.fileObj0 = data.goodsQualification.goodsQualificationFileList[0];
                            let fileUrl = data.goodsQualification.goodsQualificationFileList[0].goodsQualificationUrl.split(",")
                            fileUrl.forEach(it => {
                                data.goodsQualification.goodsQualificationFileList.forEach((item, index) => {
                                    this.moreFileList.push({
                                        uid: index,
                                        name: item.certificateTypeDesc,
                                        url: it
                                    })
                                })
                            })

                        }

                        this.form = {
                            type: data.type,
                            spuCode: data.spuCode,
                            name: data.name,
                            returnable: data.returnable,
                            brandId: data.brandId,
                            deliveryScopeId:data.deliveryScopeId,
                            goodsQualification: {
                                qualificationId: data.goodsQualification.qualificationId,
                                goodsIsImport: data.goodsQualification.goodsIsImport,
                                goodsQualificationFileList: data.goodsQualification.goodsQualificationFileList
                            },
                            skuType: data.skuType
                        }
                    }

                    // 组合品
                    if (data.type == 2) {
                        this.skuList = data.goodsPackageList
                        this.form = {
                            type: data.type,
                            spuCode: data.spuCode,
                            name: data.name,
                            returnable: data.returnable,
                            stock: data.stock,
                            price: data.price,
                            plfPrice: data.plfPrice,
                            skuType: data.skuType,
                            deliveryScopeId:data.deliveryScopeId
                        }
                    }
                    this.bannerPicList = data.detailBannerPic.split(",")
                    this.detailPicList = data.detailPic.split(",")
                    this.fileListdetailPic = data.detailPic.split(",")
                    this.bannerPicList = this.ensureArrayLength(this.bannerPicList, 10)
                    this.detailPicList = this.ensureArrayLength(this.detailPicList, 20)

                    // this.getCategoey()
                })
            }
        },
        ensureArrayLength(arr, length) {
            // 计算需要添加多少个空字符串
            const itemsToAdd = length - arr.length;
            // 如果数组已经是目标长度，则返回原数组
            if (itemsToAdd <= 0) {
                return arr;
            }
            // 使用 Array(length) 创建一个指定长度的数组，并填充空字符串
            return [...arr, ...Array(itemsToAdd).fill('')];
        },
        // 回显分类
        getCategoey() {

            request(process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + 1 + '/0', 'get').then((res) => {
                if (res.data.code == 200) {
                    this.classifyList2 = res.data.data;
                }
            })
            request(process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + 1 + '/' + this.twoId, 'get').then((res) => {
                if (res.data.code == 200) {
                    this.classifyList3 = res.data.data;
                }
            })
            request(process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + 1 + '/' + this.threeId, 'get').then((res) => {
                if (res.data.code == 200) {
                    this.classifyList4 = res.data.data;
                }
            })
        },
        handleRules(type) {
            if(!this.form.returnable)  {
                this.$message.error('请选择是否支持7天无理由！')
                return false;
            }
            if (this.form.skuType == 1) {
                this.skuList[0].specsAttributeId = '规格'
            }

            if (this.form.type == 2) {
                if (!this.form.stock) {
                    this.$message.error('请输入库存！')
                    return false;
                }
                console.log(this.skuList, 'this.skuList')
                // 组合
                const hasNonEmptyValues = obj =>
                    Object.entries(obj).every(([key, value]) => {
                        if (key === 'barCode') return true; //条形码
                        if (key === 'jcPrice') return true; //含税运集采成本
                        if (key === 'skuCount') return true; //数量
                        if (key === 'warehouseId') return true; //仓库id
                        if (key === 'warehouseName') return true; //仓库名称
                        if (key === 'referencePriceList') return true;//参考售价
                        if (key === 'stockUpDay') return true;//供货信息
                        if (key === 'supplierCode') return true;//供应商id
                        if (key === 'jcCount') return true;
                        
                        // 其他字段校验
                        return value !== null && value !== undefined && value !== '';
                    });
                const allObjectsHaveNonEmptyValues = arr => arr.every(hasNonEmptyValues);
                if (!allObjectsHaveNonEmptyValues(this.skuList)) {
                    this.$message.warning('请填写完整规格信息！');
                    return false;
                }
            } else {
                this.form.classifyList = [this.twoId, this.threeId, this.fourId]
                const hasNonEmptyValues = obj =>
                    Object.entries(obj).every(([key, value]) => {
                        if (key === 'jcPrice') return true;
                        // if (key === 'skuCount') return true;
                        if (key === 'supplierCode') return true;
                        if (key === 'jcCount') return true;
                        // 其他字段校验
                        return value !== null && value !== undefined && value !== '';
                    });
                const allObjectsHaveNonEmptyValues = arr => arr.every(hasNonEmptyValues);
                if (!allObjectsHaveNonEmptyValues(this.skuList)) {
                    this.$message.warning('请填写完整规格信息！');
                    return false;
                }

                for (let i in this.skuList) {
                    if (this.skuList[i].referencePriceList.length == 0) {
                        this.$message.error('请填写参考售价！')
                        return false;
                    }
                }

                if (!this.form.brandId) {
                    this.$message.warning('请选择品牌！')
                    return false;
                }
            }
            if (this.form.type == 1) {
                if (this.form.goodsQualification.goodsIsImport == 0) {
                    if (this.moreFileList.length == 0) {
                        this.$message.error('请上传文件！')
                        return false;
                    }
                    if (!this.fileObj0.goodsQualificationStartTime || !this.fileObj0.goodsQualificationEndTime) {
                        this.$message.error('请选择生效时间！')
                        return false;
                    }
                    let imgUrl = [];
                    this.moreFileList.forEach(item => {
                        if (item.response) {
                            imgUrl.push(item.response.url)
                        } else {
                            console.log(item, 'item')
                            imgUrl.push(item.url)
                        }

                    })
                    imgUrl = imgUrl.join(",")
                    console.log(this.form.goodsQualification, 'this.form.goodsQualification')
                    this.form.goodsQualification = {
                        goodsIsImport: 0,
                        qualificationId: this.form.goodsQualification.qualificationId,
                        goodsQualificationFileList: [{
                            certificateType: 401,
                            goodsQualificationUrl: imgUrl,
                            goodsQualificationStartTime: this.fileObj0.goodsQualificationStartTime,
                            goodsQualificationEndTime: this.fileObj0.goodsQualificationEndTime,
                        }]
                    }
                } else {
                    this.form.goodsQualification.goodsQualificationFileList = []
                    if (!this.fileObj0.goodsQualificationUrl || !this.fileObj0.goodsQualificationStartTime || !this.fileObj0.goodsQualificationEndTime) {
                        if (!this.fileObj0.goodsQualificationUrl) {
                            this.$message.error('请上传文件！')
                            return false;
                        }
                        if (!this.fileObj0.goodsQualificationStartTime) {
                            this.$message.error('请选择生效时间！')
                            return false;
                        }
                        if (!this.fileObj0.goodsQualificationEndTime) {
                            this.$message.error('请选择失效时间！')
                            return false;
                        }

                    } else {
                        this.form.goodsQualification.goodsQualificationFileList.push(this.fileObj0)
                    }
                    if (this.fileObj1.goodsQualificationUrl || this.fileObj1.goodsQualificationStartTime || this.fileObj1.goodsQualificationEndTime) {
                        if (!this.fileObj1.goodsQualificationUrl) {
                            this.$message.error('请上传文件！')
                            return false;
                        }
                        if (!this.fileObj1.goodsQualificationStartTime) {
                            this.$message.error('请选择生效时间！')
                            return false;
                        }
                        if (!this.fileObj1.goodsQualificationEndTime) {
                            this.$message.error('请选择失效时间！')
                            return false;
                        }
                        this.form.goodsQualification.goodsQualificationFileList.push(this.fileObj1)
                    }
                    if (this.form.goodsQualification.goodsQualificationFileList.length == 0) {
                        this.$message.error('请上传品牌资质')
                        return false;
                    }
                }
            }

            if (!this.form.name) {
                this.$message.warning('请输入商品名称！')
                return false;
            }
            // if (!this.form.spuCode) {
            //     this.$message.warning('请输入供应商自查spu编码！')
            //     return false;
            // }



            if (!this.bannerPicList[0] || !this.bannerPicList[1] || !this.bannerPicList[2]) {
                this.$message.warning('商品展示图带*需上传完整！')
                return false;
            }
            if (this.detailPicList.length == 0) {
                this.$message.warning('商品描述至少需上传1张！')
                return false;
            }
            let reason = "";
            if (this.$route.query.reason) {
                reason = decodeURIComponent(this.$route.query.reason)
            }
            let obj = {}
            if (this.form.type == 1) {
                obj = {
                    "createType": type, // 存草稿 / 提交
                    "classifyList": this.form.classifyList, // 分类id
                    "type": this.form.type, // 1.单品 2.组合品
                    "spuCode": this.form.spuCode,//商品规格号
                    "name": this.form.name,//商品名称
                    "returnable": this.form.returnable,//是否七天无理由
                    "brandId": this.form.brandId,
                    "detailBannerPic": this.bannerPicList.filter(item => item !== '' && item != null).join(','),
                    "detailPic": this.detailPicList.filter(item => item !== '' && item != null).join(','),
                    "goodsQualification": this.form.goodsQualification,
                    // "goodsQualification": fileObjNew,
                    "skuType": this.form.skuType,
                    "goodsSpecList": this.skuList,
                    reason: reason
                }
            } else {
                obj = {
                    stock: this.form.stock,//库存
                    "createType": type, // 存草稿 / 提交
                    "type": this.form.type, // 1.单品 2.组合品
                    "spuCode": this.form.spuCode,//商品规格号
                    "name": this.form.name,//商品名称
                    "returnable": this.form.returnable,//是否七天无理由
                    "detailBannerPic": this.bannerPicList.filter(item => item !== '' && item != null).join(','),
                    "detailPic": this.detailPicList.filter(item => item !== '' && item != null).join(','),
                    "skuType": this.form.skuType,
                    "goodsPackageList": this.skuList,
                    price: this.form.price,
                    plfPrice: this.form.plfPrice,
                    reason: reason
                }
            }
            // this.form.reason = decodeURIComponent(this.$route.query.reason);
            this.handleOK(obj)
        },
        // 保存
        handleOK(params) {
            // type 0草稿 1提交

            if (this.$route.query.id) {
                params.id = this.$route.query.id
                request(process.env.VUE_APP_API_BASE_URL + 'srm/goods/v2/update', 'post', params).then((res) => {
                    if (res.data.code == 200) {
                        this.$message.success('操作成功')
                        EventBus.$emit('itemRemoved');
                        this.$router.replace('/product/product_spu')
                    } else {
                        this.$message.error(res.data.data)
                    }
                })
            } else {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/goods/v2/create', 'post', params).then((res) => {
                    if (res.data.code == 200) {
                        this.$message.success('操作成功')
                        EventBus.$emit('itemRemoved');
                        this.$router.replace('/product/product_spu')
                    } else {
                        this.$message.error(res.data.data)
                    }
                })
            }

        },
        // 搜索
        handleSearchChange(searchVal) {
            this.form.brandId = searchVal;
            Object.assign(this, {
                searchVal,
                fetching: false,
            });
        },
        fetchUser(value) {
            if (!value) {
                return false;
            }
            this.lastFetchId += 1;
            const fetchId = this.lastFetchId;
            this.brandList = [];
            this.fetching = true;
            request(process.env.VUE_APP_API_BASE_URL + 'srm/brand/selectList', 'post', { page: 0, size: 10, name: value }).then(res => {
                if (fetchId !== this.lastFetchId) {
                    return;
                }
                this.brandList = res.data.data.data;
                this.fetching = false;
            }).catch(error => {
                console.error('Error fetching user:', error);
            });
        },
        // 根据index返回商品图名称
        getPicTitle(index) {
            switch (index) {
                case 0:
                    return '商品正面图 白底为主图'
                case 1:
                    return '商品实物包装图 白底'
                case 2:
                    return '商品标签/配料表 白底'
                case 3:
                    return '商品正面图 （含拆包装商品）白底'
                case 4:
                    return '商品卖点细节图 白底'
                case 5:
                    return '商品卖点细节图 白底'
                case 6:
                    return '商品卖点细节图 白底'
                case 7:
                    return '商品卖点细节图 白底'
                case 8:
                    return '商品卖点细节图 白底'
                case 9:
                    return '商品卖点细节图 白底'

            }
        },
        uploadFileZMtwo(info) {
            const { fileList } = info;
            if (fileList.length > 0 && fileList[0].status === 'done') {
                console.log('上传成功:', fileList[0].response);
                // this.bannerPicList[index] = fileList[0].response.url;
                this.$set(this.fileObj1, 'goodsQualificationUrl', fileList[0].response.url);
            }
        },
        uploadFileZMone(info) {
            const { fileList } = info;
            if (fileList.length > 0 && fileList[0].status === 'done') {
                console.log('上传成功:', fileList[0].response);
                this.$set(this.fileObj0, 'goodsQualificationUrl', fileList[0].response.url);
            }
        },
        uploadMoreFile(info) {
            const { fileList } = info;
            this.moreFileList = fileList;
        },
        // 上传报告开始
        handleChangeUpload(file) {
            this.form.goodsQualification.goodsQualificationFileList[0].goodsQualificationUrl = file.file.response.url
        },
        handleChangeUpload1(file) {
            this.form.goodsQualification.goodsQualificationFileList[1].goodsQualificationUrl = file.file.response.url
        },
        onStartChange(date, dateString) {
            this.dateStartTime = date;
            if (dateString) {
                this.fileObj0.goodsQualificationStartTime = dateString + ' 00:00:00'
            } else {
                this.fileObj0.goodsQualificationEndTime = ""
            }
        },
        onEndChange(date, dateString) {
            this.dateEndTime = date;
            if (dateString) {
                this.fileObj0.goodsQualificationEndTime = dateString + ' 23:59:59'
            } else {
                this.fileObj0.goodsQualificationEndTime = ""
            }

            // this.form.goodsQualification.goodsQualificationFileList[0].goodsQualificationEndTime = dateString + ' 23:59:59'
        },
        onStartChange1(date, dateString) {
            this.dateStartTime1 = date;
            if (dateString) {
                this.fileObj1.goodsQualificationStartTime = dateString + ' 00:00:00'
            } else {
                this.fileObj1.goodsQualificationStartTime = ""
            }


            // this.form.goodsQualification.goodsQualificationFileList[1].goodsQualificationStartTime = dateString + ' 00:00:00'
        },
        onEndChange1(date, dateString) {
            this.dateEndTime1 = date;
            if (dateString) {
                this.fileObj1.goodsQualificationEndTime = dateString + ' 23:59:59'
            } else {
                this.fileObj1.goodsQualificationEndTime = ""
            }
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('上传文件类型有误!');
            }
            const isLt30M = file.size / 1024 / 1024 < 30;
            if (!isLt30M) {
                this.$message.error('Image must be smaller than 30MB!');
            }
            return isJpgOrPng && isLt30M;
        },
        beforeUploadBannerPic(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('上传文件类型有误!');
            }
            const isLt30M = file.size / 1024 / 1024 < 30;
            if (!isLt30M) {
                this.$message.error('Image must be smaller than 30MB!');
            }
            return isJpgOrPng && isLt30M;
        },
        uploadBannerPicDetailsChange(info, index) {
            const { fileList } = info;
            // 文件上传完成后，更新文件列表
            if (fileList.length > 0 && fileList[0].status === 'done') {
                // 回显图片并隐藏上传按钮
                console.log('上传成功:', fileList[0].response);
                this.$set(this.detailPicList, index, fileList[0].response.url);
            }
        },
        // 上传多图
        uploadMorePic(info) {
            this.uploadIng = true;
            const infoList = info.fileList.map(item => item.response.url);
            this.bannerPicList = ["", "", "", "", "", "", "", "", "", "",];
            let bIndex = 0;
            // 遍历 bannerPicList，填充空字符串
            this.bannerPicList.forEach((item, index) => {
                if (item === '' && bIndex < infoList.length) {
                    this.$set(this.bannerPicList, index, infoList[bIndex]);
                    bIndex++;
                }
            });
            // 如果还有未填充的链接且 bannerPicList 的长度小于 10
            for (let i = bIndex; i < infoList.length && this.bannerPicList.length < 10; i++) {
                if (!this.bannerPicList.includes(infoList[i])) {
                    this.bannerPicList.push(infoList[i]);
                }
            }
            this.uploadIng = false;
        },
        uploadMorePicDetails(info) {
            this.uploadIng2 = true;
            const infoList = info.fileList.map(item => item.response.url);
            this.detailPicList = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",];
            let bIndex = 0;
            // 遍历 bannerPicList，填充空字符串
            this.detailPicList.forEach((item, index) => {
                if (item === '' && bIndex < infoList.length) {
                    this.$set(this.detailPicList, index, infoList[bIndex]);
                    bIndex++;
                }
            });
            // 如果还有未填充的链接且 detailPicList 的长度小于 10
            for (let i = bIndex; i < infoList.length && this.detailPicList.length < 10; i++) {
                if (!this.detailPicList.includes(infoList[i])) {
                    this.detailPicList.push(infoList[i]);
                }
            }
            this.uploadIng2 = false;
        },

        uploadBannerPicChange(info, index) {
            const { fileList } = info;
            // this.$set(this.fileLists, index, fileList);
            // 文件上传完成后，更新文件列表
            if (fileList.length > 0 && fileList[0].status === 'done') {
                // 回显图片并隐藏上传按钮
                console.log('上传成功:', fileList[0].response);
                // this.bannerPicList[index] = fileList[0].response.url;
                this.$set(this.bannerPicList, index, fileList[0].response.url);

            }
        },
        uploadSkuPicChange(info, index) {
            const { fileList } = info;
            this.$set(this.skuFileList, index, fileList);
            // 文件上传完成后，更新文件列表
            if (fileList.length > 0 && fileList[0].status === 'done') {
                // 回显图片并隐藏上传按钮
                console.log('上传成功:', fileList[0].response);
                this.skuList[index].pic = fileList[0].response.url;
            }
        },
        onJKGoods() {
            this.form.goodsQualification.goodsQualificationFileList = []
            this.moreFileList = []
            if (this.form.goodsQualification.goodsIsImport == 0) {
                this.fileObj0 = {
                    certificateType: 401,
                    goodsQualificationUrl: "",
                    goodsQualificationStartTime: "",
                    goodsQualificationEndTime: ""
                }
            }
            if (this.form.goodsQualification.goodsIsImport == 1) {
                this.fileObj0 = {
                    certificateType: 402,
                    goodsQualificationUrl: "",
                    goodsQualificationStartTime: "",
                    goodsQualificationEndTime: ""
                }
                this.fileObj1 = {
                    certificateType: 403,
                    goodsQualificationUrl: "",
                    goodsQualificationStartTime: "",
                    goodsQualificationEndTime: ""
                }
            }
            this.dateStartTime = ""
            this.dateStartTime1 = ""
            this.dateEndTime = ""
            this.dateEndTime1 = ""
            this.uploadFileList = []
            this.uploadFileList1 = []
        },
        getQiniuToken() {
            if (!this.getQiniuTokenIng) {
                this.getQiniuTokenIng = true
                this.loading = true
                this.loadingText = 'token加载中'
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qiniu/token/yjhp-hb', 'get').then((res) => {
                    if (res.data.code == 200) {
                        this.postData.token = res.data.data
                        this.getQiniuTokenIng = false
                    }
                })
            }
        },

        removeFile(type) {
            if (type == 0) {
                this.form.goodsQualification.goodsQualificationFileList[0].goodsQualificationUrl = ""
                this.uploadfileList = []

            } else {
                this.form.goodsQualification.goodsQualificationFileList[1].goodsQualificationUrl = ""
                this.form.goodsQualification.goodsQualificationFileList[2].goodsQualificationUrl = ""
                this.uploadfileList = []
            }
        },
        removeFileObj(type) {
            if (type == 0) {
                this.fileObj0.goodsQualificationUrl = ""
            } else {
                this.fileObj1.goodsQualificationUrl = ""
            }
        },
        // 上传报告结束
        onChange(value) {
            console.log(value);
        },
        // 复制sku
        copySku(index) {
            let data = JSON.parse(JSON.stringify(this.skuList[index]))
            this.skuList.push(data)
        },
        delSku(index) {
            this.skuList.splice(index, 1)
        },
        editSku(index) {
            this.openGoodsConfirm = true;
            this.dataSource2 = this.skuList[index].goodsSpecPackageList
            // this.dataSource2 = 
        },
        // 打开仓库弹窗
        selCk(index) {
            this.skuListSelectIndex = index;
            this.ckOpen = true;
            this.selectCkId = this.skuList[this.skuListSelectIndex].warehouseId
            request(process.env.VUE_APP_API_BASE_URL + 'srm/warehouse/list', 'post', { page: 0, size: 300, status: 1 }).then(res => {
                this.ckList = res.data.data.data;
            })
            if (this.skuList[this.skuListSelectIndex].warehouseId) {
                this.selectCkId = this.skuList[index].warehouseId
            }
        },
        // 仓库保存
        handleCk() {
            if (this.selectedIndexCk != undefined) {
                this.skuList[this.skuListSelectIndex].warehouseId = this.ckList[this.selectedIndexCk].id;
                this.skuList[this.skuListSelectIndex].warehouseName = this.ckList[this.selectedIndexCk].name
            }
            this.ckOpen = false;
        },
        // 日期选择
        supplyChange(date, dateString) {
            this.supplyTime = dateString;
            this.supplyStartTime = dateString[0] + ' 00:00:00'
            this.supplyEndTime = dateString[1] + ' 23:59:59'
            // this.skuList[this.skuListSelectIndex].supplyStartTime = dateString[0] + ' 00:00:00'
            // this.skuList[this.skuListSelectIndex].supplyEndTime = dateString[1] + ' 23:59:59'
        },
        // 供货----
        // 供货信息打开
        selGH(index) {
            console.log(this.skuList[index], 'sssss')
            this.stockUpDay = this.skuList[index].stockUpDay;
            this.supplyStartTime = this.skuList[index].supplyStartTime;
            this.supplyEndTime = this.skuList[index].supplyEndTime;
            if (this.supplyStartTime) {
                this.supplyTime = [moment(this.supplyStartTime), moment(this.supplyEndTime),]
            } else {
                this.supplyTime = []
            }
            // if (this.skuList[index].supplyStartTime) {
            //     this.supplyTime = [moment(this.skuList[index].supplyStartTime), moment(this.skuList[index].supplyEndTime),]
            // } else {
            //     this.supplyTime = []
            // }
            this.skuListSelectIndex = index;
            this.ghOpen = true;
        },

        handleGH() {

            if (!this.supplyStartTime || !this.supplyEndTime || !this.stockUpDay) {
                this.$message.error('请补全信息！')
                return false;
            }
            this.skuList[this.skuListSelectIndex].stockUpDay = this.stockUpDay;
            this.skuList[this.skuListSelectIndex].supplyStartTime = this.supplyStartTime;
            this.skuList[this.skuListSelectIndex].supplyEndTime = this.supplyEndTime;
            // if (!this.skuList[this.skuListSelectIndex].supplyStartTime || !this.skuList[this.skuListSelectIndex].supplyEndTime || !this.skuList[this.skuListSelectIndex].stockUpDay) {
            //     this.$message.error('请补全信息！')
            //     return false;
            // }
            this.ghOpen = false;
        },
        // 供货end
        // 打开参考价弹窗
        selCKJ(index) {
            this.skuListSelectIndex = index;
            this.referencePriceList = JSON.parse(JSON.stringify(this.skuList[index].referencePriceList));
            if (this.referencePriceList.length == 0) {
                this.referencePriceList = [{ type: 1, price: null, url: "" }, { type: 2, price: null, url: "" }, { type: 3, price: null, url: "" }, { type: 4, price: null, url: "" }]
            }

            this.CKJOpen = true;
        },

        // 保存参考价
        handleCKJ() {
            // this.skuList[this.skuListSelectIndex].referencePriceList = this.referencePriceList;
            const referencePriceList = this.referencePriceList;
            let hasValidEntry = false;
            for (const item of referencePriceList) {
                if (item.price && item.url) {
                    hasValidEntry = true;
                    break;
                }
            }
            if (!hasValidEntry) {
                this.$message.error('需要填写完整一行！');
                return false;
            }
            const hasPartialEntries = referencePriceList.some(item => (item.price && !item.url) || (!item.price && item.url));
            if (hasPartialEntries) {
                this.$message.error('需要填写完整一行！');
                return false;
            }
            this.skuList[this.skuListSelectIndex].referencePriceList = this.referencePriceList;
            this.CKJOpen = false;
            // const referencePriceList = this.skuList[this.skuListSelectIndex].referencePriceList;
            // let hasValidEntry = false;
            // for (const item of referencePriceList) {
            //     if (item.price && item.url) {
            //         hasValidEntry = true;
            //         break;
            //     }
            // }
            // if (!hasValidEntry) {
            //     this.$message.error('需要填写完整一行！');
            //     return false;
            // }
            // const hasPartialEntries = referencePriceList.some(item => (item.price && !item.url) || (!item.price && item.url));
            // if (hasPartialEntries) {
            //     this.$message.error('需要填写完整一行！');
            //     return false;
            // }
            // this.CKJOpen = false;
        },

        // 选择仓库
        handleChangeCK(e) {
            console.log(e, 'ee')
            const selectedIndex = this.ckList.findIndex(option => option.id == e);
            this.selectedIndexCk = selectedIndex;
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    console.log('Received values of form: ', values);
                }
            });
        },
        handleSelectChange(value) {
            let val = value.target.value;
            console.log(val, 'val')
            if (val == 1) {
                this.skuList = [{
                    pic: "",//规格图
                    specsAttributeValue: "",//规格属性值
                    specsAttributeId: "",//规格属性ID
                    barCode: "",//条形码
                    supplierCode: "",//供应商编号
                    marketPrice: "",// 市场建议零售价
                    dfPrice: "",//含税运代发成本
                    jcCount: "",//集采数量
                    jcPrice: "",//含税运集采成本
                    // stock: "",//库存
                    skuCount: 1,// 数量
                    warehouseId: "",//仓库id
                    warehouseName: "",
                    referencePriceList: [],//参考售价
                    stockUpDay: 1
                }]
            } else {
                this.skuList = []
            }
            this.twoId = [];
            this.threeId = [];
            this.fourId = [];
            this.classifyList2 = []
            this.classifyList3 = []
            this.classifyList4 = []
            this.fileObj0 = {}
            this.fileObj1 = {}
            this.moreFileList = []
            this.dateStartTime = null
            this.dateEndTime = null
            this.dateStartTime1 = null
            this.dateEndTime1 = null
            // this.form.type = value
            // if(value == 2) {
            //     this.skuList = []
            // }
        },
        resetForm() {
            this.$refs.ruleForm.resetFields();
        },
        uploadChange(info, index) {
            const { fileList } = info;
            // 文件上传完成后，更新文件列表
            if (fileList.length > 0 && fileList[0].status === 'done') {
                // 回显图片并隐藏上传按钮
                console.log('上传成功:', fileList[0].response);
                this.referencePriceList[index].url = fileList[0].response.url
                // this.skuList[this.skuListSelectIndex].referencePriceList[index].url = fileList[0].response.url
                // this.$set(this.skuList[this.skuListSelectIndex].referencePriceList[index].url, index, fileList[0].response.url);
            }
        },
        delDownUrl(index) {
            this.referencePriceList[index].url = ""
            // this.$set(this.skuList[this.skuListSelectIndex].referencePriceList[index], 'url', '');
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
            return result
        },
    }
}
</script>

<style>
.ant-form-item-label {
    text-align: right;
}

.box {
    padding: 20px;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.16);
    margin: 10px 0 20px;
}

.box-tit {
    color: #3f8bff;
    font-size: 14px;
    font-weight: 500
}

.table-box {
    /* width: 100%; */
    min-width: 1680px;
    font-size: 14px;
}

.table-tit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    position: relative;

}

.table-tit .ant-upload-picture-card-wrapper {
    width: 80px;
}

.table-tit .ant-upload.ant-upload-select-picture-card {
    width: 80px;
    height: 80px;
}

.table-tit li {
    text-align: center;
    flex: 1;
    margin-right: 20px;
    /* min-width: 120px; */
}

.table-scroll {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    /* 防止内容换行 */
}

.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.box-img-list {
    display: flex;
    flex-wrap: wrap;
}

.box-img-list p {
    width: 120px;
}

.box-img-list li {
    margin-right: 30px;
}

.box-ms div {
    margin-top: 5px;
    color: #7f7f7f;
    font-size: 13px;
}

.box-img-list2 {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.box-img-list2 li {
    margin-right: 30px;
}

.box-img-list2>div>span {
    display: flex;
    /* align-items: center; */
    flex-wrap: wrap;
}

.box-img-list>div>span {
    display: flex;
    /* align-items: center; */
}

.sku-ul {
    display: flex;
}

.sku-ul li {
    width: 230px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}


body {
    min-width: 1500px;
    overflow-x: scroll;
}
</style>
